const player = new Plyr('#player');
const topplayer = new Plyr('#topPlayer', {muted: true});

$(document).ready(function() {
  $('.burger_menu').click(function(event) {
    $('.menu').toggleClass('menu_active')
    $('.burger_menu').toggleClass('burger_menu_active')
    $('body').toggleClass('body_lock')
  })

  $("a.menu__link")
    .add("a.nav_link")
    .on("click", function (event) {
      $('.menu').removeClass('menu_active')
      $('.burger_menu').removeClass('burger_menu_active')
      $('body').removeClass('body_lock')
      event.preventDefault();
      var id = $(this).attr("href"),
      top = $(id).offset().top;
      $("body,html").animate({ scrollTop: top }, 1500, "linear");
  });

  $('form').on('focus', 'input[type=number]', function (e) {
    $(this).on('wheel.disableScroll', function (e) {
      e.preventDefault()
    })
  })
  $('form').on('blur', 'input[type=number]', function (e) {
    $(this).off('wheel.disableScroll')
  })

  $("#form").submit(function (e) { // Устанавливаем событие отправки для формы с id=form
    e.preventDefault();
    var form_data = $(this).serialize(); // Собираем все данные из формы
    $.ajax({
        type: "POST", // Метод отправки
        url: "/send.php", // Путь до php файла отправителя
        data: form_data,
        success: function () {
          // Код в этом блоке выполняется при успешной отправке сообщения
          // alert("Your Message was send with Success. Thank You");
          $('#form').addClass('disable');
          $('.thank').removeClass('disable');
          console.log('sended')
        }
    });
  });
})